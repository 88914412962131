import React from 'react';
import styled from 'styled-components';
import ProductCard from '../elements/ProductCard';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './Rollerblinds.module.scss';
import { kathetes_persides } from '../../Data/pagesTexts.js';

function KathetesPersides() {



    return (

        <MainView>
            <ProductCard header={kathetes_persides.header} text={kathetes_persides.text}  >
                <StaticImage className={styles.imageWrapper} quality={70} layout='fullWidth' src='../../images/kathetespersides/κάθετη περσίδα 3.jpg' alt="asdasdasdas" />
            </ProductCard>
            </MainView>

    );
}

export default KathetesPersides;
const MainView = styled.div`
min-height: 100vh;
display: flex;
flex-direction:column;
align-items: center;
`;