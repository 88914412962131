import React from 'react';
import styled from 'styled-components';
import ProductCard from '../elements/ProductCard';
import { StaticImage } from 'gatsby-plugin-image';
import {imageWrapper} from './Rollerblinds.module.scss';
import About from '../About';
import { rollerBlindsData } from '../../Data/pagesTexts.js';

function Rollerblinds() {



    return (

        <MainView>
            <About backgroundColor={false} header={rollerBlindsData.headerInfo.header} text={rollerBlindsData.headerInfo.text} />
            <ProductCard header={rollerBlindsData.monochrome.header} text={rollerBlindsData.monochrome.text}  >
                <StaticImage className={imageWrapper} quality={70} layout='fullWidth' src='../../images/rolokourtines/απλά μονόχρωμα 1.jpg' alt="asdasdasdas" />
            </ProductCard>
            <ProductCard reversed header={rollerBlindsData.screen.header} text={rollerBlindsData.screen.text} >
                <StaticImage className={imageWrapper} quality={70} layout='fullWidth' src='../../images/rolokourtines/screen 1.jpg' alt="asdasdasdas" />
            </ProductCard>
            <ProductCard header={rollerBlindsData.blackout.header} text={rollerBlindsData.blackout.text} >
                <StaticImage className={imageWrapper} quality={70} layout='fullWidth' src='../../images/rolokourtines/blackout 2.jpg' alt="asdasdasdas" />
            </ProductCard>
            <ProductCard reversed header={rollerBlindsData.zebra.header} text={rollerBlindsData.zebra.text} >
                <StaticImage className={imageWrapper} quality={70} layout='fullWidth' src='../../images/rolokourtines/zebra - double roller 1.jpg' alt="asdasdasdas" />
            </ProductCard>
            <ProductCard header={rollerBlindsData.embrime.header} text={rollerBlindsData.embrime.text} >
                <StaticImage className={imageWrapper} quality={70} layout='fullWidth' src='../../images/rolokourtines/εμπριμέ 1.jpg' alt="asdasdasdas" />
            </ProductCard>
            </MainView>

    );
}

export default Rollerblinds;
const MainView = styled.div`
min-height: 100vh;
display: flex;
flex-direction:column;
align-items: center;
`;