import React from 'react';
import ComponentTabs from '../../components/tabs/Tabs';
import { Helmet } from 'react-helmet';
function Rollerblinds() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{'Olkis | Ρολοκουρτίνες , Βενετικά στόρ ξύλινα & αλουμινίου, Βlackout, Κάθετες περσίδες , Πάνελ , Πλισέ'}</title>
                <html lang={'el'} />
                <meta
                    name="description"
                    content={'Πρόκειται για υφάσματα τα οποία είναι τυλιγμένα σε ένα οριζόντιο αλουμινένιο άξονα και με τη βοήθεια ενός χειριστηρίου (χειροκίνητο ή ηλεκτρικό ), περιστρέφετε ο άξονας προς τη μία ή προς την άλλη πλευρά, με αποτέλεσμα το ύφασμα να ανεβαίνει ή να κατεβαίνει .Κατασκευάζονται σε μεγάλη ποικιλία σχεδίων και υφασμάτων, μπορούμε να εκτυπώσουμε οποιοδήποτε θέμα ή λογότυπο και κατατάσσονται στις εξής κατηγορίες Πρόκειται για μια κατηγορία ρόλλερ με μεγάλη γκάμα χρωμάτων από μονόχρωμα υφάσματα σκίασης. Τα χρησιμοποιούμε σε γραφεία, δωμάτια, επαγγελματικούς χώρους κλπ. Πρόκειται για μία κατηγορία ρόλλερ όπου το ύφασμα είναι διάτρητο. Τα χρησιμοποιούμε για να μας προσφέρουν σκίαση στον χώρο μας αλλά ταυτόχρονα μας επιτρέπει την ορατότητα προς τα έξω Βlackout Πρόκειται για μία κατηγορία ρόλλερ όπου το ύφασμα το οποίο χρησιμοποιούμε μας δίνει την δυνατότητα να έχουμε πλήρη (ολική) συσκότιση του χώρου. Το χρησιμοποιούμε σε υπνοδωμάτια, σε δωμάτια ξενοδοχείων, σε αίθουσες προβολών, σε αίθουσες συνεδριάσεων κλπ.'}
                />
            </Helmet>
            <ComponentTabs />
        </>
    );
}

export default Rollerblinds;
