import React from 'react';
import styled from 'styled-components';
import ProductCard from '../elements/ProductCard';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './Rollerblinds.module.scss';
import { panel } from '../../Data/pagesTexts.js';

function Panel() {



    return (

        <MainView>
            <ProductCard header={panel.header} text={panel.text}  >
                <StaticImage className={styles.imageWrapper} quality={70} layout='fullWidth' src='../../images/panel/πανελ 1.jpg' alt="asdasdasdas" />
            </ProductCard>
            </MainView>

    );
}

export default Panel;
const MainView = styled.div`
min-height: 100vh;
display: flex;
flex-direction:column;
align-items: center;
`;