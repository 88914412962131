import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Rollerblinds from '../rollerblinds/RollerblindsComponent';
import Plise from '../rollerblinds/Plise';
import Layout from '../Layout';
import { tabpanel, appBar } from './Tabs.module.scss';
import VenetikaStor from '../rollerblinds/Venetika_stor';
import KathetesPersides from '../rollerblinds/Kathetes_persides';
import Panel from '../rollerblinds/Panel';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//         backgroundColor: theme.palette.background.paper,
//     },
// }));

function ComponentTabs() {
    // const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        console.log(event);
        setValue(newValue);
    };
    return (
        <>
            <Layout>
                <AppBar className={appBar} style={{ backgroundColor: '#171c22ad' }} position="sticky">
                    <Tabs value={value} onChange={handleChange} variant="scrollable"
                        scrollButtons="auto" aria-label="simple tabs example">
                        <Tab label="Ρολοκουρτίνες " {...a11yProps(0)} />
                        <Tab label="Βενετικά στόρια" {...a11yProps(1)} />
                        <Tab label="Κάθετες περσίδες" {...a11yProps(2)} />
                        <Tab label="Πάνελ" {...a11yProps(3)} />
                        <Tab label="Πλισέ" {...a11yProps(4)} />
                    </Tabs>
                </AppBar>
                <TabPanel className={tabpanel} value={value} index={0}>
                    <Rollerblinds />
                </TabPanel>
                <TabPanel className={tabpanel} value={value} index={1}>
                    <VenetikaStor />
                </TabPanel>
                <TabPanel className={tabpanel} value={value} index={2}>
                    <KathetesPersides />
                </TabPanel>
                <TabPanel className={tabpanel} value={value} index={3}>
                    <Panel />
                </TabPanel>
                <TabPanel className={tabpanel} value={value} index={4}>
                    <Plise />
                </TabPanel>
            </Layout>
        </>



        // <>
        //     <Rollerblinds />
        // </>

    );
}

export default ComponentTabs;

