import React from 'react';
import styled from 'styled-components';
import ProductCard from '../elements/ProductCard';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './Rollerblinds.module.scss';
import { plise } from '../../Data/pagesTexts';

function Plise() {



    return (
        <MainView>
            <ProductCard header={plise.header} text={plise.text}  >
                <StaticImage className={styles.imageWrapper} quality={70} layout='fullWidth' src='../../images/plise/πλισε 3.jpg' alt="asdasdasdas" />
            </ProductCard>
        </MainView>
    );
}

export default Plise;
const MainView = styled.div`
min-height: 100vh;
display: flex;
flex-direction:column;
align-items: center;
`;